import React, { useEffect, useState } from "react";
import styles from './styles.module.scss';
import Button from "../Form/Button";
import ConsultarService, { OpcaoParcelamento } from "../../services/ConsultarService";
import Show from "../Show";
import { trackEvent } from "../../analytics";

type RefisBannerInstallmentsProps = {
  inscriptions: any;
  onClick: (installments: OpcaoParcelamento, inscriptions: number[]) => void;
};

export function RefisBannerInstallments(props: RefisBannerInstallmentsProps) {
  const [loading, setLoading] = useState(false);
  const [installments, setInstallments] = useState<OpcaoParcelamento[]>();
  const [maxInstallments, setMaxInstallments] = useState<number | undefined>();

  const BANNERS = [
    { installments: 4, discount: 100 },
    { installments: 1, discount: 100 },
    { installments: 10, discount: 80 },
  ];

  const getBannerTitle = (installments?: number) => {
    if (!installments) return <></>;
    if (installments === 1) return <span className={styles.title}>Pague <b>à vista</b> e ganhe</span>;
    return <span className={styles.title}><b>Parcele {installments}x</b> e ganhe</span>;
  }

  const loadInstallments = async () => {
    setLoading(true);
    const inscriptionIds = props.inscriptions.map((inscription: any) => inscription.id);
    await ConsultarService.simulacaoParcelamento(inscriptionIds)
      .then((response) => {
        const maxInstallments = response.reduce((max: number, item: any) => {
          return item.num_installments > max ? item.num_installments : max;
        }, 0);
        if (response) setInstallments(response);
        setMaxInstallments(maxInstallments);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadInstallments();
  }, []);

  const handleBannerClick = (installmentsBanner?: number) => {
    if (installmentsBanner) {
      const selected = installments?.find((i) => i.num_installments === installmentsBanner);
      const inscriptions = props.inscriptions.map((inscription: any) => inscription.id);
      if (selected) {
        props.onClick(selected, inscriptions);
        trackEvent("Click banner refis", "click_banner_refis", installmentsBanner === 1 ? "Banner de Pagamento à Vista" : `Banner de Parcelamento em ${installmentsBanner}x`);
      }
    }
  };

  return (
    <div className={styles.content}>
      <Show if={!!(maxInstallments && !loading)}>
        <div className={styles.container}>
          {maxInstallments && (() => {
            const bannersToShow = [];
            bannersToShow.push(BANNERS.find(b => b.installments === 1));
            if (maxInstallments <= 4) {
              bannersToShow.unshift({ installments: maxInstallments, discount: 100 });
            }
            else if (maxInstallments <= 10) {
              bannersToShow.unshift(BANNERS.find(b => b.installments === 4));
              bannersToShow.push({ installments: maxInstallments, discount: 80 });
            }
            else {
              bannersToShow.unshift(BANNERS.find(b => b.installments === 4));
              bannersToShow.push(BANNERS.find(b => b.installments === 10));
            }

            return bannersToShow.map((banner, index) => (
              <div key={index} className={`${styles.banner} ${banner?.installments === 1 && styles.fullPayment}`}>
                {getBannerTitle(banner?.installments)}
                <span className={styles.discount}>{banner?.discount}%</span>
                <span>de desconto sob juros*</span>
                <Button
                  className={styles.button}
                  label={banner?.installments === 1 ? 'Pagar à vista' : `Parcelar em ${banner?.installments}x`}
                  onClick={() => handleBannerClick(banner?.installments)}
                />
              </div>
            ));
          })()}
        </div>
        <span className={styles.warning}>*Desconto aplicado apenas nos encargos e juros da Taxa de Manejo de Resíduos Sólidos Urbanos</span>
      </Show>
    </div>
  );
}
