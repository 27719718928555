import GA4React from "ga-4-react";

const ga4react = new GA4React(String(process.env.REACT_APP_GA_ID));

export const initGA = async () => {
  if (process.env.NODE_ENV === "production") {
    try {
      await ga4react.initialize();
    } catch (error) {
      console.error("Erro ao inicializar Google Analytics:", error);
    }
  }
};

export const trackEvent = (category: string, action: string, label: string) => {
  if (process.env.NODE_ENV === "production") {
    ga4react.event(action, label, category);
  }
};
