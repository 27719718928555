import React, { useEffect } from "react";
import "./styles.css";
import Show from "../Show";

interface Props {
  title?: string;
  show: boolean;
  centered?: boolean;
  small?: boolean;
  onHide?(): void;
  noCloseButton?: boolean;
}

export default function Modal({ title, show, centered, onHide, children, small, noCloseButton }: React.PropsWithChildren<Props>) {
  useEffect(() => {
    if (show) {
      $('#modalDefault').modal('show');
      $('.modal-backdrop').show();
    } else {
      $('#modalDefault').modal('hide');
      $('.modal-backdrop').hide();
    }
  }, [show]);

  if (!show) {
    return null;
  }

  $('#modalDefault').on('hide.bs.modal', () => {
    if (onHide) onHide();
  });

  return (
    <div id="modalDefault" className="modal fade show" tabIndex={-1} role="dialog" style={{ display: "block", zIndex: 10000 }}>
      <div className={`modal-dialog ${centered ? "modal-dialog-centered" : ""} ${small ? "small-modal" : ""}`} role="document">
        <div className="modal-content">
          <Show if={!noCloseButton}>
            <button type="button" className="close btn-close-modal" data-dismiss="modal" aria-label="Close" onClick={onHide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </Show>
          {title && (
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
            </div>
          )}
          <div className="modal-body">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
