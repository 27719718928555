import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ExecucaoFiscal from "../views/ExecucaoFiscal";
import ConsultarDebitos from "../views/ConsultarDebitos";
import ConsultarProtesto from "../views/ConsultarProtesto";
import DuvidasFrequentes from "../views/DuvidasFrequentes";
import PaginaNaoEncontrada from "../views/PaginaNaoEncontrada";
import ConsultarParcelamentos from "../views/ConsultarParcelamentos";
import AtendimentosEspecializados from "../views/AtendimentosEspecializados";
import ValidarDocumentoAssinado from "../views/ValidarDocumentoAssinado";
import AreaAtendente from "../views/AreaAtendente";
import Login from "../views/Login"
import LandingPage from '../views/LandingPage';
import Contatos from "../views/Contatos";
import Faq from "../views/Faq";
import Noticias from "../views/Noticias";
import Noticia from "../views/Noticia";
import NoticiaTwo from "../views/NoticiaTwo";
import NoticiaThree from "../views/NoticiaThree";
import APGM from "../views/APGM";
import QuemEQuem from "../views/QuemEQuem";
import LeisRegulamentadoras from "../views/LeisRegulamentadoras";
import Servicos from "../views/Servicos";
import { VideoCall } from "../views/VideoCall";
import CentralLicitacao from "../views/CentralLicitacao";

import { initGA } from "../analytics";

initGA();

export default function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={LandingPage} />
        <Route path="/paineis/duvidas_frequentes" component={DuvidasFrequentes} />
        <Route path="/paineis/orientacoes_sobre_execucao_fiscal" component={ExecucaoFiscal} />
        <Route path="/atendimentos_especializados" component={AtendimentosEspecializados} />
        <Route path="/consultar/debitos/em_aberto" component={ConsultarDebitos} />
        <Route path="/consultar/parcelamentos" component={ConsultarParcelamentos} />
        <Route path="/consultar/protestos" component={ConsultarProtesto} />
        <Route path="/validar-documento" component={ValidarDocumentoAssinado} />
        <Route path="/area_atendente" component={AreaAtendente} />
        <Route path="/contatos" component={Contatos} />
        <Route path="/duvidas" component={Faq} />
        <Route path="/login" component={Login} />
        <Route path="/noticias" component={Noticias} />
        <Route path="/noticia" component={Noticia} />
        <Route path="/atendimento_sem_sair_de_casa" component={NoticiaTwo} />
        <Route path="/transacao_tributaria" component={NoticiaThree} />
        <Route path="/apgm" component={APGM} />
        <Route path="/quem_e_quem" component={QuemEQuem} />
        <Route path="/servicos" component={Servicos} />
        <Route path="/leis_regulamentadoras" component={LeisRegulamentadoras} />
        <Route path="/central_licitacao" component={CentralLicitacao} />
        <Route path="/video_call/:balcaoId/:id" component={VideoCall} />
        <Route path="*" component={PaginaNaoEncontrada} />
      </Switch>
    </BrowserRouter>
  );
}
