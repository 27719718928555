import React, { Children, useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faExclamationCircle, IconLookup, IconName, IconPrefix } from "@fortawesome/free-solid-svg-icons";
import { isServerError } from "../../config/api";
import Format from "../../helpers/Format";
import ConsultarService, { Status, Inscription, Billet, OpcaoParcelamento, Parcelamento } from "../../services/ConsultarService";
import Show from "../Show";
import Modal from "../Modal";
import Alert from "../Alert";
import Boleto from "../Boleto";
import Loader from "../Loader";
import ItemDebito from "../ItemDebito";
import ItemSituacao from "../ItemSituacao";
import ItemInscricao from "../ItemInscricao";
import ItemSituacaoEmpty from "../ItemSituacaoEmpty";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../helpers/Utils";
import Button from "../Form/Button";

import "./styles.css";
import { useEffect } from "react";
import moment from "moment";
import { format } from "path";
import { RefisBannerInstallments } from "../RefisBannerInstallments";

interface Props {
  documento: string;
  title: string;
  status: Status;
  reloadSearch(): void;
  totalBalances?: [] | any;
  totalBalancesRefis?: [] | any;
  enabledRefis?: boolean;
  attendant_id?: number;
  showAddress: boolean
}

interface Data {
  title: string;
  subtitle: string;
  showed: boolean;
  selectable: boolean;
  data: Inscription[];
}

const ENABLED_REFIS_RULE_ID: number[] = [109, 110, 111];

export default function ItemTipoReceita({ documento, attendant_id, title, status, reloadSearch, totalBalances, totalBalancesRefis, enabledRefis, showAddress}: React.PropsWithChildren<Props>) {
  const history = useHistory();

  const query = useQuery();
  const digital = Boolean(query.get("digital"));
  const digitalQuery = digital ? "&digital=true" : "";
  const mensagemSelecioneCreditos = "Selecione os débitos que deseja negociar";

  const [show, setShow] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showModalSimulacao, setShowModalSimulacao] = useState(false);
  const [showModalRestriction, setShowModalRestriction] = useState(false);
  const [showConfirmarParcelamento, setShowConfirmarParcelamento] = useState(false);
  const [showParcelamento, setShowParcelamento] = useState(false);
  const [showButtonEfetuarParcelamento, setShowButtonEfetuarParcelamento] = useState(false);
  const [showButtonCancelarParcelamento, setShowButtonCancelarParcelamento] = useState(false);
  const [showConfirmarCancelarParcelamento, setShowConfirmarCancelarParcelamento] = useState(false);

  const [error, setError] = useState("");
  const [errorSimulacao, setErrorSimulacao] = useState("");
  const [errorEfetuarParcelamento, setErrorEfetuarParcelamento] = useState("");
  const [errorCancelarParcelamento, setErrorCancelarParcelamento] = useState("");

  const [loading, setLoading] = useState(false);
  const [loadingSimulacao, setLoadingSimulacao] = useState(false);
  const [loadingEfetuarParcelamento, setLoadingEfetuarParcelamento] = useState(false);
  const [loadingCancelarParcelamento, setLoadingCancelarParcelamento] = useState(false);

  const [parcelamento, setParcelamento] = useState<Parcelamento>();
  const [opcoesParcelamento, setOpcoesParcelamento] = useState<OpcaoParcelamento[]>([]);
  const [opcaoSelecionada, setOpcaoSelecionada] = useState<OpcaoParcelamento>();

  const [boleto, setBoleto] = useState<Billet>();
  const [inscricoesSelecionadas, setInscricoesSelecionadas] = useState<number[]>([]);
  const [inscricoesComRestricao, setInscricoesComRestricao] = useState<Inscription[]>([]);
  const [refisHabilitado, setRefisHabilitado] = useState(false);

  const [totalSelected, setTotalSelected] = useState(0);
  const [group, setGroup] = useState<string[]>([]);
  const situacoes = Object.keys(status);

  const [id, setId] = useState("");
  const [extinctCda, setExtintiCda] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [selecteds, setSelecteds] = useState(0)
  const [allSelected, setAllSelected] = useState(false);
  const [selectedValue, setSelectedValue] = useState(0);
  const [selectedRefisValue, setSelectedRefisValue] = useState(0);

  const [showInformations, setShowInformations] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [contributorEmail, setContributorEmail] = useState('');
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [errorAdd, setErrorAdd] = useState(false);
  const [refisEnabled, setRefisEnabled] = useState(false);

  useEffect(() => {
    InscriptionsBehavior();
  });

  const [datas, setDatas] = useState<Data[]>(situacoes.map((situacao, index): Data => {
    if (index === 0) {
      return {
        title: situacao,
        subtitle: Format.currency(status[situacao].total_balance),
        showed: true,
        selectable: true,
        data: status[situacao].data,
      };
    }

    // tslint:disable-next-line: no-magic-numbers
    if (index === 2) {
      const totalInscriptions = status[situacao].total_inscriptions;

      return {
        title: situacao,
        subtitle: `${totalInscriptions} ${totalInscriptions > 1 ? "inscrições" : "inscrição"}`,
        showed: false,
        selectable: false,
        data: status[situacao].data,
      };
    }

    return {
      title: situacao,
      subtitle: "",
      showed: false,
      selectable: false,
      data: status[situacao].data,
    };
  }));

  const totalInscriptionsOpened = status[situacoes[0]].total_inscriptions;
  const label = totalInscriptionsOpened > 1 ? "inscrições" : "inscrição";

  function resetSelection() {
    datas.map((data) => {
      data.data = data.data.map((inscription) => {
        inscription.selected = false;

        return inscription;
      });

      return data;
    });
  }

  const handleHideModalRestriction = () => setShowModalRestriction(false);

  const handleHideBoleto = () => {
    setShowModal(false);
    setBoleto(undefined);
  };

  const handleHideParcelamento = () => {
    setShowParcelamento(false);
    setParcelamento(undefined);
    reloadSearch();
  };

  const handleToggleShow = () => {
    setShow(!show);
  };

  const unselectAll = () => {
    setDatas(datas.map((data) => {
      data.data = data.data.map((inscription) => {
        inscription.selected = false;

        return inscription;
      });

      return data;
    }));
  };

  const selectInscription = (inscricaoSelected: Inscription) => {
    const { id, group_payment, payment_request_code, refis_enabled } = inscricaoSelected;

    setDatas(datas.map((data, index) => {
      if (index === 0) {
        if (payment_request_code) {
          data.data = data.data.map((inscription) => {
            if (inscription.id === id && inscription.payment_request_code === payment_request_code) {
              inscription.selected = true;
            }

            return inscription;
          });
        } else {
          data.data = data.data.map((inscription) => {
            if (inscription.id === id) {
              inscription.selected = true;
            }

            return inscription;
          });
        }
      }

      return data;
    }))
    setRefisHabilitado(refis_enabled);
    checkShowButtons();
    setGroup([group_payment]);
  };

  const toggleSelectInscription = (inscricaoSelected: Inscription) => {
    setDatas(datas.map((data) => {
      data.data = data.data.map((inscription) => {
        if (inscription.id === inscricaoSelected.id) {
          inscription.selected = !inscription.selected;
        }

        return inscription;
      });

      return data;
    }));

    checkShowButtons();
  };

  const checkShowButtons = () => {
    if (datas[0]) {
      const inscriptions = datas[0].data;
      const inscriptionsSelecteds = inscriptions.filter((inscription) => inscription.selected);
      const inscriptionWithPaymentRequest = inscriptionsSelecteds.find((inscription) => inscription.payment_request_code !== null);

      if (inscriptionWithPaymentRequest && inscriptionWithPaymentRequest.payment_request_code !== null) {
        if (inscriptionWithPaymentRequest.refis_enabled) {
          const paymentRequestCode = inscriptionWithPaymentRequest.payment_request_code;
          const inscriptionsWithPaymentRequest = inscriptions.filter((inscription) => inscription.payment_request_code === paymentRequestCode);

          setShowButtonCancelarParcelamento(inscriptionsSelecteds.length === inscriptionsWithPaymentRequest.length);
          setShowButtonEfetuarParcelamento(false);
        } else {
          setShowButtonCancelarParcelamento(false);
          setShowButtonEfetuarParcelamento(false);
        }

      } else {
        setShowButtonEfetuarParcelamento(inscriptionsSelecteds.length > 0);
        setShowButtonCancelarParcelamento(false);
      }

    }
  };

  const handleSelect = (inscricaoSelected: Inscription) => () => {
    if (inscricaoSelected.group_payment) {
      if (group.includes(inscricaoSelected.group_payment)) {
        toggleSelectInscription(inscricaoSelected);
      } else {
        if (group.length) {
          unselectAll();
        }

        selectInscription(inscricaoSelected);
      }
    }

    if (datas[0]) {
      const inscriptionsSelecteds = datas[0].data.filter((i) => i.selected);
      setInscricoesSelecionadas(inscriptionsSelecteds.map((i) => i.id));
      setTotalSelected(inscriptionsSelecteds.reduce((t, i) => t + i.balance, 0));
      if (inscriptionsSelecteds.length === 0) {
        setGroup([]);
        setRefisHabilitado(false);
      }
    }
  };

  const handleSubmit = async (inscriptions?: number[]) => {
    setLoading(true);
    try {
      await ConsultarService
        .gerarBoleto(inscriptions || inscricoesSelecionadas)
        .then((response) => {
          setShowModal(true);
          setShowModalSimulacao(false);
          setTotalSelected(0);
          setInscricoesSelecionadas([]);
          resetSelection();
          setGroup([]);
          setError("");
          setBoleto(response);
        }).catch((err) => {
          if (err instanceof Error) {
            setError("Não foi possível estabelecer uma conexão com o servidor. Tente novamente mais tarde.");
          } else if (isServerError(err)) {
            setError(err.error);
          } else {
            console.error(err);
          }
        }).finally(() => setLoading(false));
    } catch (err) {
      setLoading(false);
    }
  };

  const handleConfirmarParcelamento = () => {
    setShowConfirmarParcelamento(true);
    setErrorSimulacao("");
    setErrorEfetuarParcelamento("");
  };

  const handleDesistirParcelamento = () => {
    setShowModalSimulacao(false);
    setShowConfirmarParcelamento(false);
    setOpcaoSelecionada(undefined);
    setOpcoesParcelamento([]);
    setShowInformations(false);
    setValidEmail(false);
    setAcceptTerms(false);
  };

  const handleEfetuarParcelamento = async () => {
    setLoadingEfetuarParcelamento(true);
    setErrorSimulacao("");
    setErrorEfetuarParcelamento("");
    try {
      if (opcaoSelecionada) {
        await ConsultarService
          .gerarParcelamento(inscricoesSelecionadas, opcaoSelecionada.num_installments, opcaoSelecionada.refis_rule_id, attendant_id, documento)
          .then((response) => {
            setParcelamento(response);
            handleDesistirParcelamento();
            setShowParcelamento(true);
            sendInstallmentEmail(response);
          }).catch((err) => {
            if (isServerError(err)) {
              setErrorEfetuarParcelamento(err.error);
            } else {
              console.error(err);
            }
          }).finally(() => {
            setLoadingEfetuarParcelamento(false);
          });
      } else {
        setErrorEfetuarParcelamento("Selecione uma opção para efetuar o parcelamento.");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSimulacao = async (inscriptions?: number[], installments?: OpcaoParcelamento) => {
    setShowModalRestriction(false);
    setLoadingSimulacao(true);
    setShowModalSimulacao(true);
    setParcelamento(undefined);
    setErrorEfetuarParcelamento("");
    setErrorSimulacao("");
    try {
      await ConsultarService
        .simulacaoParcelamento(inscriptions || inscricoesSelecionadas)
        .then((response) => {
          setOpcoesParcelamento(response);
          if (installments) {
            setOpcaoSelecionada(installments);
            setShowInformations(true);
          };
        }).catch((err) => {
          if (isServerError(err)) {
            setErrorSimulacao(err.error);
          } else {
            console.error(err);
          }
        }).finally(() => {
          setLoadingSimulacao(false);
        });
    } catch (err) {
      setLoadingSimulacao(false);
      console.error(err);
    }
  };

  const handleToConsultarParcelamentos = () => {
    setShowModal(false);
    setShowModalSimulacao(false);
    setShowConfirmarParcelamento(false);
    setShowParcelamento(false);
    // tslint:disable-next-line: no-magic-numbers
    setTimeout(() => history.push(`/consultar/parcelamentos?d=${documento}&n=${parcelamento?.code}${digitalQuery}`), 500);
  };

  const handleShowConfirmarCancelarParcelamento = () => {
    setShowConfirmarCancelarParcelamento(true);
  };

  const handleHideConfirmarCancelarParcelamento = () => {
    setShowConfirmarCancelarParcelamento(false);
  };

  const handleConfirmarCancelarParcelamento = async () => {
    const numeroParcelamento = datas[0].data.find((inscricao) => inscricoesSelecionadas.includes(inscricao.id))?.payment_request_code;
    if (numeroParcelamento) {
      setLoadingCancelarParcelamento(true);
      try {
        await ConsultarService
          .cancelarParcelamento(inscricoesSelecionadas, numeroParcelamento)
          .then((response) => {
            setDatas(datas.map((data) => {
              data.data = data.data.map((inscription) => {
                if (inscricoesSelecionadas.includes(inscription.id)) {
                  const inscriptionResponse = response.inscriptions.find((i) => i.id === inscription.id);

                  if (inscriptionResponse) {
                    return inscriptionResponse;
                  }
                }

                return inscription;
              });

              return data;
            }));
            checkShowButtons();
            handleHideConfirmarCancelarParcelamento();
          }).catch((err) => {
            if (isServerError(err)) {
              setErrorCancelarParcelamento(err.error);
            } else {
              console.error(err);
            }
          }).finally(() => {
            setLoadingCancelarParcelamento(false);
          });
      } catch (err) {
        setLoadingCancelarParcelamento(false);
        console.error(err);
      }
    }
  };

  const handleToConsultNegotiation = (inscription_id: number) => {
    datas.forEach((data) => {
      data.data.forEach((inscription) => {
        if (inscription_id ===inscription.id) {
          setTimeout(() => window.open(`/consultar/parcelamentos?d=${documento}&n=${inscription.payment_request_code}`, "_blank"), 500);
        }
      })
    });
  };

  const handleVerificaInscricoesComRestricoes = useCallback(() => {
    const inscricoesComRestricaoEncontrada: Inscription[] = [];

    datas.forEach((data) => {
      data.data.forEach((inscription) => {
        if (inscricoesSelecionadas.includes(inscription.id) && inscription.refis_enabled && inscription.has_restriction) {
          inscricoesComRestricaoEncontrada.push(inscription);
        }
      })
    });

    // if (inscricoesComRestricaoEncontrada.length) {
    //   setInscricoesComRestricao(inscricoesComRestricaoEncontrada);
    //   setShowModalRestriction(true);
    // } else {
      handleSimulacao();
    // }
  }, [inscricoesSelecionadas]);

  const setVisibleCdas = (element: any) => {
    const allDivs = document.getElementsByClassName("groupBody");
    const divGroupment = document.getElementById(element);
    const chieldDiv = divGroupment?.firstElementChild;

    Array.from(allDivs).map((div) => {
      const groupmentSelected = div.firstElementChild;
      if(div.classList.contains("groupBodySelected")){
        groupmentSelected?.classList.remove("groupmentsOpenned");
        div.classList.remove("groupBodySelected");
      }
    });

    setId(element);

    chieldDiv?.classList.add("groupmentsOpenned");
    divGroupment?.classList.add("groupBodySelected");

    if (id == element && chieldDiv?.classList.contains("groupmentsOpenned")) {
      setId('');
      divGroupment?.classList.remove("groupBodySelected");
      chieldDiv?.classList.remove("groupmentsOpenned");
    };

    clearSelections();
  }

  const setVisibleCdasNegotiation = (element: any) => {
    const allDivs = document.getElementsByClassName("groupBody");
    const divGroupment = document.getElementById(element);
    const chieldDiv = divGroupment?.firstElementChild;

    Array.from(allDivs).map((div) => {
      const groupmentSelected = div.firstElementChild;
      if(div.classList.contains("groupBodySelectedNegotiation")){
        groupmentSelected?.classList.remove("negotiationGroupmentSelected");
        div.classList.remove("groupBodySelectedNegotiation");
      }
    });

    setId(element);

    chieldDiv?.classList.add("negotiationGroupmentSelected");
    divGroupment?.classList.add("groupBodySelectedNegotiation");

    if (id == element && chieldDiv?.classList.contains("negotiationGroupmentSelected")) {
      setId('');
      divGroupment?.classList.remove("groupBodySelectedNegotiation");
      chieldDiv?.classList.remove("negotiationGroupmentSelected");
    };

    clearSelections();
  }

  const setVisibleExtinctCdas = (element: any) => {
    const extinctGroupment = document.getElementById(element);
    const extinticChild = extinctGroupment?.firstElementChild

    extinctGroupment?.classList.add("extinticGroupBodySelected");
    extinticChild?.classList.add("extinctGroupmentSelected");

    if(extinctCda == false) {
      setExtintiCda(true);
    }else {
      setExtintiCda(false);
      extinctGroupment?.classList.remove("extinticGroupBodySelected");
      extinticChild?.classList.remove("extinctGroupmentSelected");
    }
  }

  const icon = faChevronUp;
  const iconTitle = "Clique para visualizar";

  const icon2 = faChevronDown;
  const iconTitle2 = "Clique para esconder";

  const showCount = () => {
    const listElements = document.querySelectorAll("#checkboxInscription");
    var groupmentValue = 0;
    Array.from(listElements).map((checkBox) => {
      const inscriptionValue = parseFloat(checkBox.getAttribute("data-value") as string);
      groupmentValue = (groupmentValue + inscriptionValue);
    })
    const groupValueElement = document.getElementById(groupmentValue.toFixed(2).toString());
    const getFirstChild = groupValueElement?.firstElementChild as HTMLElement;
    const getSecoundElementChild = groupValueElement?.children[1] as HTMLElement;
    const getSecoundChild = groupValueElement?.children[2];
    const getThirdChild = groupValueElement?.lastElementChild;
    getFirstChild.classList.add("dynamicGroupHide");
    getSecoundElementChild.classList.add("dynamicGroupHide");
    getSecoundChild?.classList.remove("dynamicGroupHide");
    getThirdChild?.classList.remove("dynamicGroupHide");
  }

  const selectInscriptions = () => {
    const inputElement = document.querySelectorAll("#checkboxInscription");
    Array.from(inputElement).map((eachone) => {
      const element = eachone as HTMLElement;
      const inscriptionId = parseInt(element.getAttribute("data-id") as string);
      datas.map((data) => {
        data.data.map((inscription) => {
          if(inscription.id == inscriptionId) {
            handleSelect(inscription);
          };
        });
      });
    });
  };

  const InscriptionsBehavior = () => {
    const inputElement = document.querySelectorAll("#checkboxInscription");
    const clearButton = document.getElementById("clearButton");
    const selectionInformations = document.getElementById("inscriptionsQuantity");
    const elementButton = document.getElementById("selectAll");
    const installmentBtn = document.getElementById("installmentBtn");
    const ticketBtn = document.getElementById("ticketBtn");
    var arrayElements: Array<Element> = [];
    Array.from(inputElement).map((eachone) => {
      arrayElements.push(eachone);
      const element = eachone as HTMLElement;
      const refisEnabled =  element.getAttribute("data-refisenabled") as unknown as boolean;
      const inscriptionValue = refisEnabled ?
                                  parseFloat(element.getAttribute("data-refisvalue") as string)
                                : parseFloat(element.getAttribute("data-value") as string);
      const inscriptionRefisValue = refisEnabled ?
                                  parseFloat(element.getAttribute("data-value") as string)
                                : parseFloat(element.getAttribute("data-refisvalue") as string);
      element.onchange = function() {
        setSelectedValue(selectedValue + inscriptionValue);
        setSelectedRefisValue(selectedRefisValue + inscriptionRefisValue)
        if(element.hasAttribute("checked")){
          showCount();
          clearButton?.classList.add("clearAllSelections");
          setSelecteds(selecteds + 1);
          selectionInformations?.classList.remove("insciptionSelectedsInvisible");
          selectionInformations?.classList.add("insciptionSelecteds");
          installmentBtn?.classList.add("installmentButton");
          ticketBtn?.classList.add("ticketButton");
          installmentBtn?.classList.remove("installmentButtonDisabled");
          ticketBtn?.classList.remove("ticketButtonDisabled");
          selectInscriptions();

        }else {
          setSelecteds(selecteds - 1);
          setAllSelected(false);
          setIsChecked(false);
          elementButton?.classList.remove("selectAllButtonMarked");
          selectionInformations?.classList.remove("insciptionSelectedsInvisible");
          selectionInformations?.classList.add("insciptionSelecteds");
          setSelectedValue(selectedValue - inscriptionValue);
          setSelectedRefisValue(selectedRefisValue - inscriptionRefisValue)
          selectInscriptions();
        };
        if (!(arrayElements.find(element => element.hasAttribute("checked")))) {
          clearSelections();
          setSelecteds(0);
          setSelectedValue(0);
          setSelectedRefisValue(0)
          installmentBtn?.classList.remove("installmentButton");
          ticketBtn?.classList.remove("ticketButton");
          installmentBtn?.classList.add("installmentButtonDisabled");
          ticketBtn?.classList.add("ticketButtonDisabled");
        };
        const elementsCheked = arrayElements.filter(item => (item as HTMLInputElement).checked);
        if(elementsCheked.length == arrayElements.length){
          setIsChecked(true);
          setAllSelected(true);
          elementButton?.classList.add("selectAllButtonMarked");
          selectionInformations?.classList.add("insciptionSelectedsInvisible");
          selectionInformations?.classList.remove("insciptionSelecteds");
        };
      };
    });
  };

  const MarkAllInscriptions = (selected : boolean, groupPayment : string, refisEnabled: boolean) => {
    if (datas[0]) {
      datas[0].data.map((items) => {
        if(items.group_payment === groupPayment) {
          items.selected = selected;
          setGroup([groupPayment]);
          setRefisHabilitado(refisEnabled);
          checkShowButtons();
        }
      })
      const inscriptionsSelecteds = datas[0].data.filter((i) => i.selected);
      setInscricoesSelecionadas(inscriptionsSelecteds.map((i) => i.id));
      setTotalSelected(inscriptionsSelecteds.reduce((t, i) => t + i.balance, 0));
      if (inscriptionsSelecteds.length === 0) {
        setGroup([]);
        setRefisHabilitado(false);
      }
    }
  }

  const selectAllInscriptions = (element : any) => {
    const elementButton = document.getElementById(element);
    const listElements = document.querySelectorAll("#checkboxInscription");
    const Elements = listElements[0] as HTMLElement;
    const grouPayment = Elements.getAttribute("data-grouppayment") as string;
    const refisEnabled = (Elements.getAttribute("data-refisEnabled") == 'false' ? false : true);
    const selectionInformations = document.getElementById("inscriptionsQuantity");
    const installmentBtn = document.getElementById("installmentBtn");
    const ticketBtn = document.getElementById("ticketBtn");
    if(isChecked) {
      setIsChecked(false);
      setSelecteds(0);
      clearSelections();
      setAllSelected(false);

      elementButton?.classList.remove("selectAllButtonMarked");
      const clearButton = document.getElementById("clearButton");
      clearButton?.classList.remove("clearAllSelections");
      selectionInformations?.classList.add("insciptionSelectedsInvisible");
      selectionInformations?.classList.remove("insciptionSelecteds");
      installmentBtn?.classList.remove("installmentButton");
      ticketBtn?.classList.remove("ticketButton");
      installmentBtn?.classList.add("installmentButtonDisabled");
      ticketBtn?.classList.add("ticketButtonDisabled");
      Array.from(listElements).map((box) => {
        box.removeAttribute("checked");
        const boxElement = box as HTMLInputElement;
        boxElement.checked = false;
      })
      MarkAllInscriptions(false, grouPayment, refisEnabled);
    }else {
      setIsChecked(true);
      setAllSelected(true);
      setSelectedValue(0);
      setSelectedRefisValue(0)
      setSelecteds(listElements.length);

      showCount();
      MarkAllInscriptions(true, grouPayment, refisEnabled);

      const clearButton = document.getElementById("clearButton");
      elementButton?.classList.add("selectAllButtonMarked");
      clearButton?.classList.add("clearAllSelections");
      installmentBtn?.classList.add("installmentButton");
      ticketBtn?.classList.add("ticketButton");
      installmentBtn?.classList.remove("installmentButtonDisabled");
      ticketBtn?.classList.remove("ticketButtonDisabled");
      var totalValue = 0
      var totalRefisValue = 0
      Array.from(listElements).map((box) => {
        const refisEnabled = box.getAttribute("data-refisenabled") as unknown as boolean;
        totalValue = refisEnabled ?
                                totalValue + parseFloat(box.getAttribute("data-refisvalue") as string)
                              : totalValue + parseFloat(box.getAttribute("data-value") as string);
        totalRefisValue = refisEnabled ?
                                totalRefisValue + parseFloat(box.getAttribute("data-value") as string)
                              : totalRefisValue + parseFloat(box.getAttribute("data-refisvalue") as string);
        box.setAttribute("checked", "true");
        const boxElement = box as HTMLInputElement;
        boxElement.checked = true;
      })
      setSelectedValue(totalValue);
      setSelectedRefisValue(totalRefisValue);
      selectionInformations?.classList.add("insciptionSelectedsInvisible");
      selectionInformations?.classList.remove("insciptionSelecteds");
    }
  }

  const clearSelections = () => {
    setIsChecked(false);
    setAllSelected(false);
    setSelecteds(0);
    setSelectedValue(0);
    setSelectedRefisValue(0);
    const selectionInformations = document.getElementById("inscriptionsQuantity");
    const removeAll = document.getElementById("selectAll");
    const clearButton = document.getElementById("clearButton");
    const elementsUnmark = document.querySelectorAll("#checkboxInscription");
    const installmentBtn = document.getElementById("installmentBtn");
    const ticketBtn = document.getElementById("ticketBtn");
    removeAll?.classList.remove("selectAllButtonMarked");
    clearButton?.classList.remove("clearAllSelections");
    Array.from(elementsUnmark).map((box) => {
      box.removeAttribute("checked");
      const boxElement = box as HTMLInputElement;
      boxElement.checked = false;
    })

    hideReset();

    selectionInformations?.classList.add("insciptionSelectedsInvisible");
    selectionInformations?.classList.remove("insciptionSelecteds");
    installmentBtn?.classList.remove("installmentButton");
    ticketBtn?.classList.remove("ticketButton");
    installmentBtn?.classList.add("installmentButtonDisabled");
    ticketBtn?.classList.add("ticketButtonDisabled");
  }

  const hideReset = () => {
    const listElements = document.querySelectorAll("#checkboxInscription");
    var groupmentValue = 0

    Array.from(listElements).map((checkBox) => {
      const inscriptionValue = parseFloat(checkBox.getAttribute("data-value") as string);
      groupmentValue = (groupmentValue + inscriptionValue);
    })

    const groupValueElement = document.getElementById(groupmentValue.toFixed(2).toString());
    if(groupValueElement != null || groupValueElement == 0){
      const getFirstChild = groupValueElement?.firstElementChild as HTMLElement;
      const getSecoundElementChild = groupValueElement?.children[1] as HTMLElement;
      const getSecoundChild = groupValueElement?.children[2];
      const getThirdChild = groupValueElement?.lastElementChild;
      getFirstChild.classList.remove("dynamicGroupHide");
      getSecoundElementChild.classList.remove("dynamicGroupHide")
      getSecoundChild?.classList.add("dynamicGroupHide");
      getThirdChild?.classList.add("dynamicGroupHide");
    }

    if (datas[0]) {
      datas[0].data.map((items) => {
        items.selected = false;
      })
      const inscriptionsSelecteds = datas[0].data.filter((i) => i.selected);
      setInscricoesSelecionadas(inscriptionsSelecteds.map((i) => i.id));
      setTotalSelected(inscriptionsSelecteds.reduce((t, i) => t + i.balance, 0));
      if (inscriptionsSelecteds.length === 0) {
        setGroup([]);
        setRefisHabilitado(false);
      };
    };
  };

  const groupmentTitle = (oppenedInsciptions: number, isAgreement: boolean) => {
    if (isAgreement) {
      return <span className="opennedNegotiation">{`Negociação em aberto com ${oppenedInsciptions} ${oppenedInsciptions > 1 ? "Inscrições" : "Inscrição"}`}</span>
    } else {
      return <span>{`${oppenedInsciptions} ${oppenedInsciptions > 1 ? "Inscrições" : "Inscrição"} em aberto`}</span>
    }
  }

  const groupmentsString = (groupment: string) => {
    if (groupment == null) {
      return 'sent_protest';
    } else {
      return groupment;
    }
  }

  const handleSelecionarOpcao = (opcao: OpcaoParcelamento) => () => {
    setOpcaoSelecionada(opcao);
    setShowInformations(true);
  };

  const termsAccepted = () => { !acceptTerms ? setAcceptTerms(true) : setAcceptTerms(false) };

  const validateEmail = () => {
    const validationemail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email);
    const inputField = (document.getElementById('emailrequired')as HTMLInputElement);
    if(inputField.value.length === 0){
      setValidEmail(false);
    }else if(validationemail) {
      inputField.classList.remove('errorfield');
      setValidEmail(true);
      setErrorAdd(false);
    }else {
      inputField.classList.add('errorfield');
      setValidEmail(false);
      setErrorAdd(true);
    }
  };

  const updateAndValidate = (e: string) => {
    setEmail(e)
    validateEmail();
  };

  const submitAttendance = async () => {
    const contributor_document = documento
    const contributor_attendance_histories = {'attendance_place':'Portal do Contribuinte - Consultar Dívidas',
                                              'attendant':'Assitente Virtual',
                                              'attendance_subject':'term_accepted'};
    const attendanceData = {contributor_document, contributor_attendance_histories};

    await ConsultarService
      .attendanceRegistration(attendanceData)
  };

  const aceptAndContinue = () => {
    handleConfirmarParcelamento()
    submitAttendance();
  };

  const sendInstallmentEmail = async (response: any) => {
    const contributor_document = documento
    const installment_code = response.code;
    const emailData = contributorEmail
    const installmentData = {contributor_document: contributor_document, installment_code: installment_code, emailData: emailData}
    await ConsultarService
      .sendEmailConfirmation(installmentData)
  }

  const returRefisValue = () => (
    totalBalances.map((balancewithoutRefis: any) => (
      balancewithoutRefis.type == title &&
      <div className="totalTributeBalanceRefis">
        <strong>Valor total do tributo com </strong>
        <strong className="refisLabel">REFIS</strong>
        <strong>: </strong>
        <span className="balanceValue">{Format.currency(balancewithoutRefis.balance)}</span>
      </div>
    ))
  );

  const returnTributeValuesRefisEnabled = () => {
    return (
      totalBalancesRefis.map((balanceInfo: any) => {
        if(balanceInfo.type == title) {
          return(
            <div className="totalTributeBalance">
              <strong>Valor total do tributo: </strong>
              {Format.currency(balanceInfo.balance)}
              {returRefisValue()}
            </div>
          )
        }
      })
    )
  };

  const returnTributeValues = () => {
    return (
      totalBalances.map((balanceInfo: any) => {
        if(balanceInfo.type == title) {
          return(
            <div className="totalTributeBalance">
              <strong>Valor total do tributo: </strong>
              {Format.currency(balanceInfo.balance)}
            </div>
          )
        }
      })
    )
  }

  const handleBannerClick = (installments: OpcaoParcelamento, inscriptions: number[]) => {
    if (installments.num_installments === 1) handleSubmit(inscriptions);
    else {
      handleSimulacao(inscriptions, installments);
    };
  }

  return (
    <div className="itenContent">
      <div className="detailedInformations">
        <Show if={show}>
          <Modal title="Alerta de restrição" show={showModalRestriction} onHide={handleHideModalRestriction}>
            <div className="simulacao-parcelamento text-center">
              <p className="text-center">
                Algumas das inscrições selecionadas possui restrições de quantidade
                de parcelas a serem parceladas. Segue abaixo as inscrições que estão
                com restrições:
              </p>
              <ul className="list-unstyled">
                {inscricoesComRestricao.map((inscricao) => (
                  <li key={inscricao.id}>CDA: {inscricao.cda}</li>
                ))}
              </ul>
              <p className="text-center">
                Isso afetará a quantidade de vezes que seu parcelamento poderá ser realizado.<br/>
                Deseja continuar?
              </p>
              <button className="btn btn-outline-primary" style={{marginRight: "10px"}} onClick={handleHideModalRestriction}>Não</button>
              <button className="btn btn-primary" onClick={() => handleSimulacao()}>Sim</button>
            </div>
          </Modal>
          <Modal title="Confirmação de cancelamento" show={showConfirmarCancelarParcelamento} onHide={handleHideConfirmarCancelarParcelamento}>
            <div className="simulacao-parcelamento text-center">
              {errorCancelarParcelamento && <Alert>{errorCancelarParcelamento}</Alert>}
              <p className="text-center">O cancelamento do seu parcelamento atual implica modificação das regras atuais e a adesão aos benefícios do novo Programa. Deseja continuar?</p>
              <button className="btn btn-outline-primary" disabled={loadingCancelarParcelamento} style={{marginRight: "10px"}} onClick={handleHideConfirmarCancelarParcelamento}>Não</button>
              <button className="btn btn-primary" disabled={loadingCancelarParcelamento} onClick={handleConfirmarCancelarParcelamento}>Sim</button>
            </div>
          </Modal>
          <Modal show={showModal} onHide={handleHideBoleto}>
            {boleto && <Boleto boleto={boleto} onHide={handleHideBoleto} />}
          </Modal>
          <Modal title="Parcelamento" show={showParcelamento} onHide={handleHideParcelamento}>
            {parcelamento && (
              <div className="modal-parcelamento">
                <p>Seu parcelamento foi efetuado com sucesso.</p>
                <p>Utilize o número do parcelamento abaixo para consultar e emitir o boleto.</p>
                <p><strong>Número do Parcelamento: {parcelamento.code}</strong></p>
                <button className="btn btn-outline-primary" onClick={handleToConsultarParcelamentos}>
                  Consultar Parcelamento
                </button>
              </div>
            )}
          </Modal>
          <Modal title={showConfirmarParcelamento ? "" : "Simulação de Parcelamento"} show={showModalSimulacao} onHide={handleDesistirParcelamento}>
            <Show if={!showConfirmarParcelamento}>
              <div className="simulacao-parcelamento">
                {errorSimulacao && <Alert>{errorSimulacao}</Alert>}
                {refisHabilitado && (
                  <>
                    <p>
                      Parcelamento simulado no Refis-TMRSU com os descontos previsto na Lei Nº 11.521/2025.
                    </p>
                    <p>
                      Os descontos não incidem sobre o valor original, conforme Art. 8º, sendo aplicados somente sobre os encargos e a atualização monetária.
                      Parágrafo Único. Os encargos e a atualização monetária a que se refere o caput incluem juros de mora, multas e quaisquer outros acréscimos legais incidentes sobre o débito principal.
                    </p>
                  </>
                )}
                <p>Clique em uma das opções abaixo para selecionar a quantidade de parcelas:</p>
                <div className="parcelas">
                  {loadingSimulacao && <Loader label="Carregando parcelas..." />}
                  {opcoesParcelamento.map((opcao) => (
                    <button
                      key={opcao.num_installments}
                      title={opcaoSelecionada?.num_installments === opcao.num_installments ? "Clique para desmarcar" : "Clique para selecionar"}
                      className={opcaoSelecionada?.num_installments === opcao.num_installments ? "selected" : ""}
                      onClick={handleSelecionarOpcao(opcao)}
                    >
                      <span className="quantidade">{opcao.num_installments}x</span>
                      <span className="descricao">{opcao.simulation}</span>
                      {opcao.discount_percent &&
                        <div>
                          <span className="desconto">{opcao.discount_percent}% de desconto</span>
                          <span className="valueWithoutDiscount">
                            ({opcao.value_saved})
                          </span>
                        </div>
                      }
                    </button>
                  ))}
                  {!loadingSimulacao && opcoesParcelamento.length === 0 && (
                    <div className="alert alert-warning">
                      O parcelamento em questão não alcançou o "Valor Mínimo da Parcela"
                      estabelecido no decreto Nº 17.015, Art. 7°, § 2º. Por isso
                      ele só poderá ser pago à vista. Para pagar à vista clique
                      no botão "Gerar boleto à vista".
                    </div>
                  )}
                </div>
                <Show if={showInformations}>
                  <div>
                    <div className="warning">
                      <FontAwesomeIcon icon={faExclamationCircle} />
                      <span>Leia o ato e concorde para prosseguir com a negociação.</span>
                    </div>
                    <div className="termsTittle">
                      <h6>ATO n° 001/2023</h6>
                      <h6>Fortaleza/CE, 09 de janeiro de 2023</h6>
                    </div>
                    <div className="termContent">
                      <span className="termTheme"><strong>Tema:</strong> prescrição no parcelamento</span>
                      <span className="terTextContent">
                        <p>
                          Declaro estar ciente de que o pedido de parcelamento do crédito tributário,
                          presencial ou online, interrompe a prescrição (o prazo de cobrança volta a correr do zero)
                          e implica no reconhecimento da existência da dívida mediante confissão extrajudicial,
                          ainda que posteriormente indeferido ou não paga a primeira parcela
                          (Código Tributário Nacional, art. 174, parágrafo único, inciso IV - STJ, Súmula 653).
                        </p>
                      </span>
                    </div>
                    <div className="acceptBox">
                      <input type="checkbox" onClick={termsAccepted}></input>
                      <span>CONCORDO</span>
                    </div>
                  </div>
                </Show>
                <p className="infos">
                  Informamos que os valores acima apresentados são apenas uma simulação.
                  Ao efetuar o parcelamento, os valores das parcelas serão reajustados
                  mensalmente conforme a legislação vigente (SELIC).
                </p>
                <div className="inputEmail">
                  <Show if={showInformations}>
                    <span>Digite seu e-mail.</span>
                    <input
                      id="emailrequired"
                      type="text"
                      placeholder="seuemail@email.com"
                      className="emailForCodeInput"
                      onChange={(e) => updateAndValidate(e.target.value)}
                      onBlur={(e) => setContributorEmail(e.target.value)}
                      autoComplete="off"
                    />
                  </Show>
                </div>
                <div className="actions">
                  {!loadingSimulacao && opcoesParcelamento.length === 0 && (
                    <>
                      <button
                        className="btn btn-outline-primary"
                        disabled={loading}
                        onClick={handleDesistirParcelamento}
                      >
                        Fechar
                      </button>
                      <button
                        className="btn btn-primary"
                        disabled={loading}
                        onClick={() => handleSubmit()}
                      >
                        {!loading && "Gerar boleto à vista"}
                        {loading && "Gerando boleto à vista..."}
                      </button>
                    </>
                  )}
                  {!loadingSimulacao && opcoesParcelamento.length > 0 && (
                    <button
                      className="btn btn-primary"
                      disabled={!(acceptTerms && validEmail)}
                      onClick={aceptAndContinue}
                    >
                      Efetuar parcelamento
                    </button>
                  )}
                </div>
              </div>
            </Show>
            <Show if={showConfirmarParcelamento}>
              <div className="simulacao-parcelamento">
                {errorEfetuarParcelamento && <Alert>{errorEfetuarParcelamento}</Alert>}
                <h2>Informações importantes!</h2>
                <ul>
                  <li>1. Para ativar o parcelamento é preciso efetuar o pagamento da primeira parcela.</li>
                  <li>2. Se a ativação não for feita até o vencimento, o parcelamento será cancelado.</li>
                  <li>3. Se o parcelamento estiver ativo e acumular três parcelas não pagas, o parcelamento será perdido.</li>
                  <li>4. As parcelas, da segunda em diante, ficarão com o vencimento para o último dia útil de cada mês.</li>
                  <li>5. A cada parcelamento perdido, o próximo parcelamento efetuado sofrerá uma redução de até 6 parcelas.</li>
                </ul>.
                <h2>Somente para REFIS - Taxa do Lixo (LEI Nº 11.521):</h2>
                <ul>
                  <li>6. O parcelamento será cancelado automaticamente nas seguintes hipóteses:</li>
                  <ul>
                    <li>I - inadimplemento de 3 (três) parcelas consecutivas ou alternadas;</li>
                    <li>II - inadimplência de qualquer parcela por período superior a 3 (três) meses.</li>
                  </ul>
                  <li>7. O cancelamento do parcelamento implicará a exigibilidade imediata do saldo devedor remanescente e sua inscrição ou reinscrição na Dívida Ativa do Município, perdendo-se os descontos a que se refere o art. 8º desta Lei sobre o saldo remanescente.</li>
                </ul>
                <div className="actions">
                  <button
                    className="btn btn-outline-primary"
                    disabled={loadingEfetuarParcelamento}
                    onClick={handleDesistirParcelamento}
                  >
                    Desistir
                  </button>
                  <button
                    className="btn btn-primary"
                    disabled={loadingEfetuarParcelamento}
                    onClick={handleEfetuarParcelamento}
                  >
                    Ok. Efetuar parcelamento!
                  </button>
                </div>
              </div>
            </Show>
          </Modal>
        </Show>
        <div className="contentInformation">
            {datas.map((data, index) => {
              var groupId: Array<string> = []
              var groupnumber = 1;
              var groupnumberNegotiations = 1
              var oppenedInsciptions = 0;
              var groupValue = 0;
              var groupValueLegalAction = 0;
              var balanceToLegalAction = 0;
              var isrefisEnabled : boolean;
              var extinctInscriptions = 0;
              var inscriptionTitle;
              var dynamicId = 0;
              let totalTributeValue: number = 0;
              var requestedAt: string;
              var paymentCode: string | null;

              if (index === 1) {
                if (data.data.length === 0) {
                  return null;
                }
                return (
                  <ItemSituacao
                    key={data.title}
                    title={data.title}
                    subtitle={data.subtitle}
                    showed={data.showed}
                  >
                    <ItemSituacaoEmpty>Existem créditos a inscrever. Por favor, procure o atendimento presencial.</ItemSituacaoEmpty>
                  </ItemSituacao>
                );
              }
            {data.data.map((inscriptionGroup) => (
              !(groupId.includes(inscriptionGroup.group_payment)) && groupId.push(inscriptionGroup.group_payment)
            ))}
            return (
              <div>
                {error && <Alert>{error}</Alert>}
                {index === 0 &&
                  (<p className="initialInformation">
                    <span>
                      {data.data.some(inscricao =>
                          inscricao.refis_enabled === true &&
                          ((inscricao.refis_rule_id !== undefined && ENABLED_REFIS_RULE_ID.includes(inscricao.refis_rule_id)) ||
                          inscricao.payment_request_code === null)
                        ) ?
                          returnTributeValuesRefisEnabled()
                        : returnTributeValues()
                      }
                    </span>
                  </p>)}
                {groupId.map((groupments) => {
                  let withStateRestriction = false;
                  let withJudicialProcess = false;
                  let penalty = false;
                  if (groupments != null || (groupments == null && data.showed == true)){
                    oppenedInsciptions = 0;
                    groupValue = 0;
                    groupValueLegalAction = 0;
                    var isAgreement = false;
                    var inscriptionId: number;
                    data.data.map((inscricao) => {
                      if (inscricao.group_payment == groupments && inscricao.payment_request_code != null ) {
                        isAgreement = true;
                        inscriptionId = inscricao.id;
                      }
                      if (inscricao.group_payment == groupments && inscricao.with_state_restriction == true) {
                        withStateRestriction = true;
                      }
                      if (inscricao.group_payment == groupments && inscricao.judicial_process_number && !inscricao.penalty) {
                        withJudicialProcess = true;
                      }
                      if(inscricao.group_payment == groupments && inscricao.penalty){
                        penalty = true
                      }
                    })
                    return (
                      <div className="groupBody" id={groupments != null ? groupments : 'sent_protest'}>
                        <div className={`${isAgreement ? 'negotiationGroupment' : 'groupments'}`} id={groupments != null ? groupments : 'sent_protest'} onClick={(e) => {isAgreement ? setVisibleCdasNegotiation(e.currentTarget.id) : setVisibleCdas(e.currentTarget.id)}}>
                          <div className="groupmentInformations">
                            {data.data.map((inscricao) => {
                              if(inscricao.group_payment == groupments && data.showed == true){
                                oppenedInsciptions ++
                                groupValue =  groupValue + inscricao.balance
                                groupValueLegalAction = groupValueLegalAction + inscricao.balance_to_legal_action
                                isrefisEnabled = inscricao.refis_enabled &&
                                  ((inscricao.refis_rule_id !== undefined && ENABLED_REFIS_RULE_ID.includes(inscricao.refis_rule_id)) || inscricao.payment_request_code === null);
                                requestedAt = inscricao.requested_at
                                paymentCode = inscricao.payment_request_code
                              }
                            })}
                            <strong>
                              {isAgreement ? `Negociação ${groupnumberNegotiations ++}` : withStateRestriction ? `Dívidas em aberto com restrição` :
                                                                    withJudicialProcess ? `Dívidas em fase Judicial` : 'Dívidas em fase Administrativa'}
                              <span className="requestedDate">{isAgreement && `Data da negociação: ${moment(requestedAt).format('DD/MM/YYYY [às] HH:mm')}`}</span>
                            </strong>
                            {groupmentTitle(oppenedInsciptions, isAgreement)}
                          </div>
                          <div className={`${isrefisEnabled ? "groumpentValuesWithRefis" : "groumpentValues"}`} id={(groupValue).toFixed(2).toString()}>
                            <div id={groupments != null ? groupments : 'sent_protest'} className="groupGeneralValue">
                              <strong>{`${ isAgreement ? 'Total: ' : 'Total em aberto: '}`}</strong>
                              <span>{Format.currency(groupValueLegalAction)}</span>
                              <strong className="colapseIcon">
                                {id == groupmentsString(groupments) ? <FontAwesomeIcon icon={icon} title={iconTitle} /> : <FontAwesomeIcon icon={icon2} title={iconTitle2} />}
                              </strong>
                            </div>
                            <div className="groupmentValuesWithREFIS">
                              <Show if={isrefisEnabled}>
                                <div id="refisValueInfo">
                                  <strong>{`${ isAgreement ? 'Total com ' : 'Total em aberto com '}`}</strong>
                                  <div className="ifRefisEnabled">
                                    <span>REFIS</span>
                                  </div>
                                  :
                                  <strong className="balanceToLegalAction">
                                    {Format.currency(groupValue)}
                                  </strong>
                                </div>
                              </Show>
                              <strong className="colapseIconRefisValue">
                                {id == groupmentsString(groupments)
                                  ? <FontAwesomeIcon icon={icon} title={iconTitle} />
                                  : <FontAwesomeIcon icon={icon2} title={iconTitle2} />}
                              </strong>
                            </div>
                            <div className="dynamicGroupHide" id="dynamicGroupInfo">
                              <strong>Total Selecionado: </strong>
                              <span className="totalSelected">{Format.currency(selectedValue)}</span>
                              <strong className="colapseIconSelected">
                                {id == groupmentsString(groupments) ? <FontAwesomeIcon icon={icon} title={iconTitle} /> : <FontAwesomeIcon icon={icon2} title={iconTitle2} />}
                              </strong>
                            </div>
                            <div className="dynamicGroupHide" id="dynamicGroupInfoREFIS">
                              <Show if={isrefisEnabled}>
                                <div id="refisValueInfo">
                                  <strong>Total Selecionado com</strong>
                                  <div className="ifRefisEnabled">
                                    <span>REFIS</span>
                                  </div>
                                  <strong>: </strong>
                                  <span className="totalSelected">{Format.currency(selectedRefisValue)}</span>
                                </div>
                              </Show>
                              <strong className="colapseIconRefisValue">
                                {id == groupmentsString(groupments)
                                  ? <FontAwesomeIcon icon={icon} title={iconTitle} />
                                  : <FontAwesomeIcon icon={icon2} title={iconTitle2} />}
                              </strong>
                            </div>
                          </div>
                        </div>
                        <Show if={id == groupmentsString(groupments)}>
                          <div className="groupmentDebt">
                            <Show if={isrefisEnabled}>
                              <RefisBannerInstallments
                                inscriptions={data.data}
                                onClick={(installments: OpcaoParcelamento, inscriptions: number[]) => handleBannerClick(installments, inscriptions)}
                              />
                            </Show>
                            <div className="inscriptionsCounter">
                              {selecteds == 0 && isAgreement == false ? <span className="selectDebts">
                              {groupments != null
                                ? "Selecione os débitos desse agrupamento que deseja negociar:"
                                : "Agrupamento em fase indisponível para negociação"}</span>
                                : isAgreement ?
                                <div className="selectDebtsAgreement">
                                  Caso queira quitar antecipadamente uma negociação ou CDA específica, selecione e clique em "Gerar boleto"
                                </div>
                              : <span className="selectDebts">
                                  Clique nos botões ao lado para gerar boleto parcelado ou à vista:
                                </span>}
                            </div>
                            <div className="paymentGroupOptions">
                              <div className="rightInformationspayment">
                                <div className="selectionInformations">
                                  <Show if={groupmentsString(groupments) != 'sent_protest'}>
                                    <div className="selectAllButton" id="selectAll" onClick={(e) => {selectAllInscriptions(e.currentTarget.id)}}>
                                      <input type="checkbox" id="checkboxId" checked={isChecked}></input>
                                      <span> Selecionar todos</span>
                                    </div>
                                  </Show>
                                  <div className="clearAllSelectionsInvisible" id="clearButton" onClick={clearSelections}>
                                    <span>Limpar seleção</span>
                                  </div>
                                </div>
                              </div>
                              <div className="leftInformationspayment">
                                <div className="paymentButtons">
                                  <div>
                                    <Button
                                      label={isAgreement ? `Consultar parcelamento ${paymentCode}` : 'Simular Parcelamento'}
                                      loadingLabel="Carregando opções..."
                                      disabled={isAgreement ? false : totalSelected === 0}
                                      loading={loadingSimulacao}
                                      onClick={isAgreement ? () => {handleToConsultNegotiation(inscriptionId)} : handleVerificaInscricoesComRestricoes}
                                      title={`${mensagemSelecioneCreditos}.`}
                                    />
                                  </div>
                                  <div>
                                    <Button
                                      label="Gerar boleto à vista"
                                      loadingLabel="Gerando boleto à vista..."
                                      disabled={totalSelected === 0}
                                      loading={loading}
                                      onClick={() => handleSubmit()}
                                      title={`${mensagemSelecioneCreditos}.`}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {data.data.map((inscricao)=> {
                              if(inscricao.group_payment == groupments){
                                return (
                                  <div className="itemInscription" id="inscription">
                                    <ItemInscricao
                                    key={inscricao.id}
                                    inscriptionId={inscricao.id}
                                    inscricaoAdministrativa={inscricao.administrative_inscription}
                                    balanceToLegalAction ={inscricao.balance_to_legal_action}
                                    saldoDevedor={inscricao.balance}
                                    numeroCda={inscricao.cda}
                                    status={inscricao.state_pt_br}
                                    documento={documento}
                                    numeroParcelamento={inscricao.payment_request_code}
                                    exercise={inscricao.debts[0].fiscal_date}
                                    registry={inscricao.debts[0].protests_info[0]?.registry_name}
                                    registryAddress={inscricao.debts[0].protests_info[0]?.full_address}
                                    registryPhone={inscricao.debts[0].protests_info[0]?.registry_phone}
                                    selected={!!inscricao.selected}
                                    locked={!inscricao.group_payment}
                                    onSelect={handleSelect(inscricao)}
                                    phase={inscricao.phases}
                                    processNumber={inscricao.judicial_process_number}
                                    groupPayment={inscricao.group_payment}
                                    refis_enabled={inscricao.refis_enabled &&
                                      ((inscricao.refis_rule_id !== undefined && ENABLED_REFIS_RULE_ID.includes(inscricao.refis_rule_id)) || inscricao.payment_request_code === null)}
                                    balance_to_l_action={inscricao.balance_to_legal_action}
                                    full_address={inscricao.full_address}
                                    attendantCounter={showAddress}
                                    receipt_type_id={inscricao.receipt_type_id}
                                    >
                                      {inscricao.debts.map((debito) => (
                                        <ItemDebito
                                          key={debito.id}
                                          exercicio={debito.fiscal_date}
                                          valor={debito.debt_balance}
                                          status={debito.status}
                                        />
                                      ))}
                                    </ItemInscricao>
                                  </div>
                                )
                              }
                            })}
                          </div>
                        </Show>
                      </div>
                    )
                  } else if (groupments == null && data.showed == false) {
                    extinctInscriptions = 0
                    dynamicId ++
                    return (
                      <div className="extinticGroupBody" id="extinticGroup">
                        <div className="extinctGroupment" id="extinticGroup" onClick={(e) => {setVisibleExtinctCdas(e.currentTarget.id)}}>
                          <div className="groupmentInformations">
                            <strong>Extintos </strong>
                            {data.data.map((inscricao) => {
                              if(inscricao.group_payment == null && data.showed == false){
                                extinctInscriptions ++
                                oppenedInsciptions ++
                              }
                            })}
                            <span>
                              {oppenedInsciptions} {oppenedInsciptions > 1 ? inscriptionTitle = "Inscrições" : inscriptionTitle = "Inscrição"}
                            </span>
                          </div>
                          <div className="groumpentValues">
                            <strong className="colapseIconExtintic">
                              {document.getElementById("extinticGroup")?.classList.contains("extinticGroupBodySelected") ?
                                <FontAwesomeIcon icon={icon} title={iconTitle} /> :
                                <FontAwesomeIcon icon={icon2} title={iconTitle2} />
                              }
                            </strong>
                          </div>
                        </div>
                        <div>
                          <Show if={extinctCda}>
                            <div className="extinctGroupmentDebt">
                              {data.data.map((inscricao) => {
                                if(inscricao.group_payment == null && data.showed == false) {
                                  return(
                                    <ItemInscricao
                                      key={inscricao.id}
                                      inscriptionId={inscricao.id}
                                      inscricaoAdministrativa={inscricao.administrative_inscription}
                                      balanceToLegalAction ={inscricao.balance_to_legal_action}
                                      saldoDevedor={inscricao.balance}
                                      numeroCda={inscricao.cda}
                                      status={inscricao.state_pt_br}
                                      documento={documento}
                                      numeroParcelamento={inscricao.payment_request_code}
                                      exercise={inscricao.debts[0].fiscal_date}
                                      registry={inscricao.debts[0].protests_info[0]?.registry_name}
                                      registryAddress={inscricao.debts[0].protests_info[0]?.full_address}
                                      registryPhone={inscricao.debts[0].protests_info[0]?.registry_phone}
                                      selected={!!inscricao.selected}
                                      locked={!inscricao.group_payment}
                                      onSelect={handleSelect(inscricao)}
                                      phase={inscricao.phases}
                                      processNumber={inscricao.judicial_process_number}
                                      groupPayment={inscricao.group_payment}
                                      refis_enabled={inscricao.refis_enabled &&
                                        ((inscricao.refis_rule_id !== undefined && ENABLED_REFIS_RULE_ID.includes(inscricao.refis_rule_id)) || inscricao.payment_request_code === null)}
                                      balance_to_l_action={inscricao.balance_to_legal_action}
                                      full_address={inscricao.full_address}
                                      attendantCounter={showAddress}
                                      receipt_type_id={inscricao.receipt_type_id}
                                      >
                                        {inscricao.debts.map((debito) => (
                                          <ItemDebito
                                            key={debito.id}
                                            exercicio={debito.fiscal_date}
                                            valor={debito.debt_balance}
                                            status={debito.status}
                                          />
                                        ))}
                                    </ItemInscricao>
                                  )
                                }
                              })}
                            </div>
                          </Show>
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
