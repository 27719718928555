import React from 'react';
import './styles.scss';

export function EmptyList() {
  return (
    <div className='emptyListContainer'>
      <p>No momento não há atendentes disponíveis.</p>

      <span><strong>Horário de atendimento</strong></span>
      <span>segunda - sexta</span>
      <span>08:00 - 16:30</span>
    </div>
  );
}
