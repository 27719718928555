import React from "react";
import Layout from "../../components/Layout";
import "./style.css";
import Gabriela from "../../assets/images/landingPage/gabriela.png";
import JoaoPaulo from "../../assets/images/landingPage/joao-paulo.png";
import ValeriaMorais from "../../assets/images/landingPage/valeria-morais.jpeg";
import Marcela from "../../assets/images/landingPage/dra-marcela.jpeg";
import HelioChagas from "../../assets/images/landingPage/helio-chagas.png";
import RomuloLeitao from "../../assets/images/landingPage/romulo-leitao.png";
import Rodrigo from "../../assets/images/landingPage/rodrigo.jpg";
import { borderRadius } from "react-select/src/theme";


export default function Faq() {
  return (
    <Layout title="Quem é quem" header className="quemEQuem" balcaoVirtual menuName="institucional">
      <p className="">Conheça a equipe PGM</p>

      <div className="row">
        <div className="col-md-6 col-sm-12">
          <img src={HelioChagas} className="img-fluid" style={{borderRadius: 28}}/>
        </div>
        <div className="col-md-6 col-sm-12 mt-3 mt-md-0">
          <h5 className="procurador-titulo">Procurador-geral</h5>
          <h4 className="nome-procurador">Hélio das Chagas Leitão Neto</h4>
          <p>
            Bacharel em Direito pela Universidade Federal do Ceará (UFC). É Pós-Graduado em Direito Processual Penal, Mestre e Doutor em Direito Constitucional.
            Foi Presidente da OAB/CE por dois mandatos (2004/2006 e 2007/2009) e Professor da UNI7 (Centro Universitário 7 de Setembro).
            No Governo do Estado, exerceu os cargos de Assessor para Assuntos Internacionais (2013 a 2014) e de Secretário de Estado da Justiça e Cidadania (2015 a 2017).
            Foi Procurador-Geral da Assembleia Legislativa (2019 a 2021) e pela segunda vez, consecutiva, é Conselheiro Federal da OAB – Secção Ceará (2019 a 2022 e 2022 a 2025).
            Ocupou a presidência da Comissão Nacional de Direitos Humanos do Conselho Nacional da OAB (2019-2022) e foi representante do órgão no Conselho Nacional de Direitos Humanos/Ministério de Direitos Humanos e Cidadania (2023-2024).
            Exerceu o cargo de Assessor Jurídico e de Relações Institucionais da Presidência da Assembleia Legislativa do Ceará (02/2022 12/2024).
          </p>
        </div>
      </div>
      <hr className="line-bottom-container" />

      <div className="row">
        <div className="col-md-4 col-sm-12">
          <img src={RomuloLeitao} alt="" width={285} height={279} style={{borderRadius: 28 }}/>
        </div>
        <div className="col-md-8 col-sm-12 mt-3 mt-md-0">
          <h5 className="procurador-titulo">Subprocurador-Geral</h5>
          <h4 className="nome-procurador">Rômulo Leitão</h4>
          <p>
            Graduado em Direito pela Faculdade de Direito (1992), com especialização em direito público (2014), pela Universidade Federal do Ceará (1992), mestrado (2008) e doutorado (2013) em Direito Constitucional pela Universidade de Fortaleza, com doutorado-sanduíche e Pós-doutorado em Ciência Política pela Boston University, Massachusetts, EUA (2014).
            É docente nos programas de Pós-Graduação em Direito (mestrado e doutorado) e de Mestrado em Direito e Gestão de Conflitos e Direito Constitucional do Curso de Direito, na Unifor.
            Procurador municipal, nível 20, da Prefeitura de Fortaleza, onde já atuou como Procurador-Geral (2001 a julho de 2004).
            Foi Diretor Geral da Câmara Municipal de Fortaleza (2005 a 2009) e Assessor Institucional da Vice-Prefeitura de Fortaleza (2009 a 2010).
          </p>
        </div>
      </div>
      <hr className="line-bottom-container" />

      <div className="row">
        <div className="col-md-4 col-sm-12">
          <img src={Rodrigo} alt="" width={285} height={279} style={{borderRadius: 28}}/>
        </div>
        <div className="col-md-8 col-sm-12 mt-3 mt-md-0">
          <h5 className="procurador-titulo">Subprocurador-Geral</h5>
          <h4 className="nome-procurador">Rodrigo C. Dias</h4>
          <p>
            Bacharel em Direito pela Universidade de Fortaleza (Unifor).
            É Pós-Graduado em Direito Eleitoral pelo Instituto Brasileiro de Desenvolvimento e Pesquisa (IDP) e Direito Processual Penal pela Unifor.
            Foi Assessor Jurídico do Gabinete da Prefeita de Fortaleza entre 2005 e 2011 e Assessor Especial entre 2011 e 2012.
            Exerceu o cargo de Assessor Jurídico Parlamentar na Câmara dos Deputados em Brasília, entre 2015 e 2017.
            Foi Secretário-Geral da Comissão de Direito Eleitoral da OAB/CE.
            Advogado com atuação em diversas campanhas eleitorais em âmbito municipal e estadual.
            É Subprocurador-Geral da Procuradoria-Geral do Município de Fortaleza (2025).
          </p>
        </div>
      </div>
      <hr className="line-bottom-container" />

      <div className="row">
        <div className="col-md-4 col-sm-12">
          <img src={ValeriaMorais} alt="" width={285} height={279} style={{borderRadius: 28}} />
        </div>
        <div className="col-md-8 col-sm-12 mt-3 mt-md-0">
          <h5 className="procurador-titulo">Subprocuradora-Geral</h5>
          <h4 className="nome-procurador">Valéria Moraes Lopes</h4>
          <p>
            Graduada em Direito pela Universidade de Fortaleza, especialista em Direito Processual Civil pela Universidade Federal
            do Ceará em parceria com a Escola Superior da Magistratura do Ceará e mestre em Direito na área de
            concentração em Relações Privadas e Desenvolvimento pelo Centro Universitário 7 de Setembro - UNI7.
            Procuradora do Município de Fortaleza de carreira desde 2006, ocupou, ao longo de sua trajetória profissional,
            os cargos públicos de Analista Judiciário do Tribunal de Justiça do Estado do Ceará e Defensor Público do Estado do Ceará.
            É Subprocuradora-Geral do Município.
          </p>
        </div>
      </div>
      <hr className="line-bottom-container" />

      <div className="row">
        <div className="col-md-4 col-sm-12">
          <img src={Marcela} alt="" width={285} height={279} style={{borderRadius: 28}} />
        </div>
        <div className="col-md-8 col-sm-12 mt-3 mt-md-0">
          <h5 className="procurador-titulo">Subprocuradora-Geral</h5>
          <h4 className="nome-procurador">Marcela Vila Nova</h4>
          <p>
            Procuradora do Município de Fortaleza desde 2018.
            Também é membro da Câmara da Prevenção e Resolução de Conflitos da Administração Pública Municipal de Fortaleza,
            desde a implantação. Vice-Presidente da Associação dos Procuradores da Administração Centralizada de Fortaleza (APACEFOR).
            Advogada atuante nas áreas de Direito Eleitoral e Direito Público Ex-Analista Judiciário do TRE-CE (2012-2018).
            Mestranda em Direito e Gestão de Conflitos na Universidade de Fortaleza –
            UNIFOR Pós-graduada em Direito Tributário pela Universidade Anhanguera-Uniderp Co-autora das obras Direito Municipal Em Debate
            (vol. 8 - Editora Forum) e Questões Discursivas Comentadas – Procuradoria Geral do Município (Editora JusPodivm)
          </p>
        </div>
      </div>
      <hr className="line-bottom-container" />

      <div className="row">
        <div className="col-md-4 col-sm-12">
          <img src={JoaoPaulo} alt="" />
        </div>
        <div className="col-md-8 col-sm-12 mt-3 mt-md-0">
          <h5 className="procurador-titulo">Subprocurador-Geral</h5>
          <h4 className="nome-procurador">João Paulo de Sousa Barbosa Nogueira</h4>
          <p>
            Advogado formado pela Universidade de Fortaleza – UNIFOR. Pós graduação em Direito Processual Civil.
            Consultor Jurídico na área de Licitações e Contratos e Direito Empresarial. Procurador do Município de Pacajus.
            Possui mais de 15 anos de experiência nas searas contenciosa e consultiva.
            Atua há mais de 8 (oito) anos na área de licitações e contratos junto à Procuradoria Geral do Município de Fortaleza.
          </p>
        </div>
      </div>
      <hr className="line-bottom-container" />
      <div className="table-responsive">
        <table className="table ">
          <thead>
            <tr>
              <th className="border-left-yellow" scope="col">Setor</th>
              <th className="title-table" scope="col">
                <div className="border-left border-white" style={{width: 100}}>
                  Ramal
                </div>
              </th>
              <th className="title-table" scope="col">
                <div className="border-left border-white">
                  Responsável
                </div>
              </th>
              <th className="title-table" scope="col">
                <div className="border-left border-white">
                  E-mail
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" className="border-left-yellow">Procuradoria de Desenvolvimento e Pesquisa</th>
              <td className="border-left text-center">2018-0172</td>
              <td className="border-left">Marcelo Sampaio Siqueira</td>
              <td className="border-left">marcelo.siqueira@pgm.fortaleza.ce.gov.br</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">Procuradoria da Fazenda Pública</th>
              <td className="border-left text-center">2180-0711</td>
              <td className="border-left">Arthur Régis Frota Carneiro Araújo</td>
              <td className="border-left">atendimento.da@pgm.fortaleza.ce.gov.br</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">Procuradoria de Processo Administrativo Disciplinar</th>
              <td className="border-left text-center">2018-0167</td>
              <td className="border-left">Eliza Maria Moreira Barbosa</td>
              <td className="border-left">edilene.luca@pgm.fortaleza.ce.gov.br</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">Procuradoria de Urbanismo e Meio Ambiente</th>
              <td className="border-left text-center">2018-0198</td>
              <td className="border-left">Pedro Ricardo Pinto da Silva</td>
              <td className="border-left">pgm.prourma@pgm.fortaleza.ce.gov.br</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">Consultoria Geral</th>
              <td className="border-left text-center">2018-0176</td>
              <td className="border-left">Márcio Augusto de Vasconcelos Diniz</td>
              <td className="border-left">pja@pgm.fortaleza.ce.gov.br</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">Procuradoria Judicial</th>
              <td className="border-left text-center">2018-0186</td>
              <td className="border-left">João Afrânio Montenegro </td>
              <td className="border-left">joaoafranio@secrel.com.br</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">Coordenadoria de Gestão de Tecnologia da Informação</th>
              <td className="border-left text-center">2180-1684</td>
              <td className="border-left">Catulo Hansen</td>
              <td className="border-left">catulo.hansen@pgm.fortaleza.ce.gov.br</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">Procuradoria da Administração Indireta - PROCADIN</th>
              <td className="border-left text-center">2180-1696</td>
              <td className="border-left">André Diego de Lima Leite</td>
              <td className="border-left">andre.leite@pgm.fortaleza.ce.gov.br</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">Procuradoria de Licitações, Contratos Administrativos e Controle Externo - PROLIC</th>
              <td className="border-left text-center">2180-1694</td>
              <td className="border-left">Daniel Medeiros de Albuquerque</td>
              <td className="border-left">daniel.albuquerque@pgm.fortaleza.ce.gov.br</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">Coordenadoria Administrativo-Financeira</th>
              <td className="border-left text-center">2018-0208</td>
              <td className="border-left">Caio Correia Moreira</td>
              <td className="border-left">caio.moreira@pgm.fortaleza.ce.gov.br</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Layout>
  );
}
